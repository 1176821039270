import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/vercel/path0/src/layout/Default.js";
import ChecklistResourcesSection from "../../../components/ChecklistResourcesSection/ChecklistResourcesSection.jsx";
import { checklists } from "../../../layout/Constant.js";
export const _frontmatter = {};
const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};
const InlineNotification = makeShortcode("InlineNotification");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <ChecklistResourcesSection figmaUrl={checklists.componentAnchor.figmaUrl.android} codeUrl={checklists.componentAnchor.codeUrl.android} checklists={checklists.componentAnchor.checklists} mdxType="ChecklistResourcesSection" />
    <div className="divi" />
    <p>{`Anchor text is text that can be clicked and hyperlinked with other sites or pages. The use of anchor text is very important in the world of content marketing because it can increase index and ranking on search engines.`}</p>
    <div {...{
      "className": "line",
      "style": {
        "backgroundColor": "white"
      }
    }}>{`
  `}<div parentName="div" {...{
        "className": "item-one",
        "align": "center"
      }}>{`
    `}<div parentName="div" {...{
          "style": {
            "padding": "7px 0"
          }
        }}>{`
      `}<img parentName="div" {...{
            "src": "https://legion-legion-ui-images-public-stage.oss-ap-southeast-5.aliyuncs.com/legion/component/anchor/android-1.png",
            "alt": "android - anchor",
            "width": 350,
            "height": 350,
            "align": "center"
          }}></img>{`
    `}</div>{`
  `}</div>
    </div>
    <div className="divi" />
    <h2>{`Usage`}</h2>
    <p>{`To implement Anchortext we can use this tag `}<inlineCode parentName="p">{`LgnAnchorTextLarge`}</inlineCode>{` or `}<inlineCode parentName="p">{`LgnAnchorTextSmall`}</inlineCode>{` .`}</p>
    <p>{`The following is usage to implement Anchortext in XML`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-xml"
      }}>{`<com.telkom.legion.component.anchortext.LgnAnchorTextLarge
  android:layout_width="wrap_content"
  android:layout_height="wrap_content"
  android:layout_marginTop="@dimen/dimen_16dp"
  android:layout_marginStart="@dimen/dimen_16dp"
  android:text="Text Only"/>

<com.telkom.legion.component.anchortext.LgnAnchorTextSmall
  android:layout_width="wrap_content"
  android:layout_height="wrap_content"
  android:layout_marginTop="@dimen/dimen_16dp"
  android:layout_marginStart="@dimen/dimen_16dp"
  android:text="Text Only"/>
`}</code></pre>
    <div className="divi" />
    <h2>{`Variants`}</h2>
    <p>{`We have two size variants, small and large variants`}</p>
    <h3><strong parentName="h3">{`Large Size`}</strong></h3>
    <div {...{
      "className": "line",
      "style": {
        "backgroundColor": "white"
      }
    }}>{`
  `}<div parentName="div" {...{
        "className": "item-one",
        "align": "center"
      }}>{`
    `}<div parentName="div" {...{
          "style": {
            "padding": "7px 0"
          }
        }}>{`
      `}<img parentName="div" {...{
            "src": "https://legion-legion-ui-images-public-stage.oss-ap-southeast-5.aliyuncs.com/legion/component/anchor/android-2.png",
            "width": 250,
            "height": 250,
            "align": "center",
            "resize-mode": "contain"
          }}></img>{`
    `}</div>{`
  `}</div>
    </div>
    <br />
    <h4>{`Static in Xml`}</h4>
    <pre><code parentName="pre" {...{
        "className": "language-xml",
        "metastring": "title=\"layout_sample.xml\"",
        "title": "\"layout_sample.xml\""
      }}>{`<com.telkom.legion.component.anchortext.LgnAnchorTextLarge
  android:layout_width="wrap_content"
  android:layout_height="wrap_content"
  android:layout_marginTop="@dimen/dimen_16dp"
  android:layout_marginStart="@dimen/dimen_16dp"
  android:text="Text Only"/>
`}</code></pre>
    <h4>{`Dynamic using Kotlin`}</h4>
    <pre><code parentName="pre" {...{
        "className": "language-js",
        "metastring": "title=\"SampleFragment.kt\"",
        "title": "\"SampleFragment.kt\""
      }}>{`...
with(binding) {
  containerBase.addView( //ViewGroup for Dynamic Layout
      LgnAnchorTextLarge(requiredContext()).apply {
          //Your View's customization here
      },
      LinearLayout.LayoutParams( //For example we use viewgroup LinearLayout
          LinearLayout.LayoutParams.MATCH_PARENT,
          LinearLayout.LayoutParams.WRAP_CONTENT
      )
    )
}
...
`}</code></pre>
    <InlineNotification kind="warning" mdxType="InlineNotification">
      <p><strong parentName="p">{`*`}{`) :`}</strong>{` Dynamic using Kotlin mean if you using Dynamic Layout or Declarative UI using kotlin`}</p>
    </InlineNotification>
    <br />
    <br />
    <h3><strong parentName="h3">{`Small Size`}</strong></h3>
    <div {...{
      "className": "line",
      "style": {
        "backgroundColor": "white"
      }
    }}>{`
  `}<div parentName="div" {...{
        "className": "item-one",
        "align": "center"
      }}>{`
    `}<div parentName="div" {...{
          "style": {
            "padding": "7px 0"
          }
        }}>{`
      `}<img parentName="div" {...{
            "src": "https://legion-legion-ui-images-public-stage.oss-ap-southeast-5.aliyuncs.com/legion/component/anchor/android-3.png",
            "width": 250,
            "height": 250,
            "align": "center",
            "resize-mode": "contain"
          }}></img>{`
    `}</div>{`
  `}</div>
    </div>
    <br />
    <h4>{`Static in Xml`}</h4>
    <pre><code parentName="pre" {...{
        "className": "language-xml",
        "metastring": "title=\"layout_sample.xml\"",
        "title": "\"layout_sample.xml\""
      }}>{`<com.telkom.legion.component.anchortext.LgnAnchorTextSmall
  android:layout_width="wrap_content"
  android:layout_height="wrap_content"
  android:layout_marginTop="@dimen/dimen_16dp"
  android:layout_marginStart="@dimen/dimen_16dp"
  android:text="Text Only"/>
`}</code></pre>
    <h4>{`Dynamic using Kotlin`}</h4>
    <pre><code parentName="pre" {...{
        "className": "language-js",
        "metastring": "title=\"SampleFragment.kt\"",
        "title": "\"SampleFragment.kt\""
      }}>{`...
with(binding) {
  containerBase.addView( //ViewGroup for Dynamic Layout
      LgnAnchorTextSmall(requiredContext()).apply {
          //Your View's customization here
      },
      LinearLayout.LayoutParams( //For example we use viewgroup LinearLayout
          LinearLayout.LayoutParams.MATCH_PARENT,
          LinearLayout.LayoutParams.WRAP_CONTENT
      )
    )
}
...
`}</code></pre>
    <InlineNotification kind="warning" mdxType="InlineNotification">
      <p><strong parentName="p">{`*`}{`) :`}</strong>{` Dynamic using Kotlin mean if you using Dynamic Layout or Declarative UI using kotlin`}</p>
    </InlineNotification>
    <br />
    <br />
    <div className="divi" />
    <h2>{`Attributes`}</h2>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Attribute Name`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Xml Attrs`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Related method(s)`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Description`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Text`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`android:text`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`text`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`To set Text value directly via xml`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Enable Status`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`android:enabled`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`isEnable`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`To set enable or disable anchor text directly via xml`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Underline`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`app:withUnderLine`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`isWithUnderLine`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`To set underline anchor text directly via xml`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Icon on Front Text`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`app:startIconDrawable`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`startIconDrawable`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`To set icon on front anchor text directly via xml`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Icon on Behind Text`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`app:endIconDrawable`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`endIconDrawable`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`To set icon on behind anchor text directly via xml`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Anchor Text Color`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`app:anchorTextColor`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`anchorTextColor`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`To set color anchor text on enable state directly via xml`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Anchor Text Color Disabled`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`app:anchorTextColorDisabled`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`anchorTextColorDisabled`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`To set color anchor text on disable state directly via xml`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Set Click Listener`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`N/A`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`setOnClickListener{ //Your Action }`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`To set click listener`}</td>
        </tr>
      </tbody>
    </table>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      